import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import GGFXImage from "@components/elements/GGFXImage"
import React, { useEffect, useState } from "react"
import "./Banner.scss"
import { usePropertyStore } from "@components/SearchResults/store/propertyStore"
import BannerContent from "./BannerContent"
import { isEmpty } from "lodash"
import { Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import CTALink from "@components/elements/CTALink"
import HomeVideo from "../../images/home.webm"
import { StaticImage } from "gatsby-plugin-image"
import useDeviceMedia from "@hooks/useDeviceMedia"
import useInteractive from "@hooks/useInteractive"
import BannerMenu from "./BannerMenu"
// import { isbot } from "isbot";
const BannerHome = ({
  bannerData,
  ggfx_results,
  strapiID,
  menuData,
  team,
  breadcrumbURLs,
  dontShowCTAIcon,
  office,
  isGuide,
  bannerlanding,
  isOffice
}) => {
  const { hasInteracted } = useInteractive()
  const { reset } = usePropertyStore((state) => ({ reset: state.reset }))
  useEffect(() => {
    reset()
  }, [])
  const {
    banner_video,
  } = bannerData
  // const isBotTrue = typeof window !== "undefined" && isbot(navigator.userAgent)
 

  return (
    <div className="banner-wrap banner-landing-wrap">
      <BannerMenu />
      <Container>
        <div className="bg-section">
          {!isEmpty(banner_video) && !bannerlanding &&
            (
              <video
                poster="/images/video-thumbnail.webp"
                className={"home-banner-video active"}
                src={HomeVideo}
                // src={banner_video?.video?.url}
                alt="banner-video"
                // ref={videoRef}
                preload="auto"
                playsInline
                loop
                muted
                autoPlay
              />
            )

          }

          {/* {!hasInteracted && */}
          <div>
            <div className="d-block d-md-none">
            <StaticImage
              src="../../images/home-mobile.jpg"
              alt="gradient-bg"
              className="home-banner-video"
              loading="eager"
              placeholder="blurred"
              quality="100"
              formats={["webp"]}
              layout="fullWidth"
            />
            </div>
          <div className="d-none d-md-block">
            <StaticImage
              src="../../images/video-thumbnail.webp"
              alt="gradient-bg"
              className="home-banner-video"
              loading="eager"
              placeholder="blurred"
              quality="100"
              formats={["webp"]}
              layout="fullWidth"
            />
            </div>
            </div>
        {/* }  */}
        </div>
        <Breadcrumbs menuData={menuData} staticURLs={breadcrumbURLs} whiteColor />
        <BannerContent
          bannerData={bannerData}
          ggfx_results={ggfx_results}
          strapiID={strapiID}
          team={team}
          office={office}
          bannerlanding
          dontShowCTAIcon={dontShowCTAIcon}
          isHome
        />
      </Container>
    </div>
  )
}

export default BannerHome
